import React from 'react';
import { Link } from 'react-router-dom';
import { Footer, Header } from '../../components/common';
import { RegisterBanner } from '../../components/images';
import { links } from '../../domain/links.enum';

export default function Register() {
  return (
    <>
      <Header />
      <section className='register-page ptb-50'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-7 mx-auto'>
              <div className='sec-heading mt-5'>
                <h2>EdChimp Registration</h2>
                <p>Fill the required fields to register</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <a className='card2' href='#'>
                <div className='register-card'>
                  <div className='card-center'>
                    <div className='card-circle'>
                      <span className='material-symbols-rounded'>
                        account_circle
                      </span>
                    </div>
                    <div className='card-heading'>
                      <h3>Students</h3>
                      <p>
                        Are you considering studying abroad in countries like
                        Canada, the United States, the United Kingdom,
                        Australia, or Ireland? Allow our team of experts to
                        assist you throughout your educational journey.
                      </p>
                    </div>
                    <div className='student-register'>
                      <Link
                        to={links.registerAsStudent}
                        className='btn primary-btn'
                      >
                        Student Registration
                      </Link>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className='col-md-6'>
              <a className='card2' href='#'>
                <div className='register-card'>
                  <div className='card-center'>
                    <div className='card-circle'>
                      <span className='material-symbols-rounded'>
                        account_circle
                      </span>
                    </div>
                    <div className='card-heading'>
                      <h3>Recruitment Partners</h3>
                      <p>
                        Do you recruit prospective students who want to study in
                        Canada, the United States, the United Kingdom,
                        Australia, or Ireland? Register to become a Recruitment
                        Partner.
                      </p>
                    </div>
                    <div className='student-register'>
                      <Link
                        to={links.registerAsAgent}
                        className='btn primary-btn'
                      >
                        Recruiter Registration
                      </Link>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className='already-register'>
        <img src={RegisterBanner}></img>
        <div className='already-register-content'>
          <h2>Already a Registered User?</h2>
          <div className='login-already'>
            <Link to={links.login} className='btn secondary-btn mw-150'>
              Login
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
