import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/custom.css';
import './assets/css/animate.min.css';

import 'react-tooltip/dist/react-tooltip.css';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './assets/css/wysiwyg.css';
import 'react-loading-skeleton/dist/skeleton.css';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import setAuthToken from './domain/setAuthToken';
import { links } from './domain/links.enum';
import { lazy, useEffect } from 'react';
import { Suspense } from 'react';
import { currentUser } from './store/actions/auth_action';
import ProtectedRoute from './shared/routes/PrivateRoutes';
import BeforeLoginRoute from './shared/routes/BeforeLoginRoutes';

import Register from './containers/auth/Register';
import RegisterAsAgent from './containers/auth/RegisterAsAgent';
import RegisterAsStudent from './containers/auth/RegisterAsStudent';
import Login from './containers/auth/Login';

const ForgotPassword = lazy(() => import('./containers/auth/ForgotPassword'));
const { RecoveryEmailed } = lazy(() =>
  import('./containers/auth/RecoveryEmailed')
);
const ResetPassword = lazy(() => import('./containers/auth/ResetPassword'));
const VerifyEmail = lazy(() => import('./containers/auth/VerifyEmail'));
const GeneralInformation = lazy(() =>
  import('./containers/profile/general_info/GeneralInformation')
);
const EducationHistory = lazy(() =>
  import('./containers/profile/education_history/EducationHistory')
);
const TestScore = lazy(() =>
  import('./containers/profile/test_score/TestScore')
);
const VisaAndStudyPermit = lazy(() =>
  import('./containers/profile/visa-and-study-permit/VisaAndStudyPermit')
);
const DashboardHome = lazy(() => import('./containers/dashboard/DashboadHome'));
const Students = lazy(() => import('./containers/students/Students'));
const SingleStudent = lazy(() => import('./containers/students/SingleStudent'));
const AddStudent = lazy(() => import('./containers/students/AddStudent'));
const AddSchool = lazy(() => import('./containers/school/AddSchool'));
const SingleSchool = lazy(() => import('./containers/school/SingleSchool'));
const Schools = lazy(() => import('./containers/school/Schools'));
const Programs = lazy(() => import('./containers/programs/Programs'));
const AddProgram = lazy(() => import('./containers/programs/AddProgram'));
const SingleProgram = lazy(() => import('./containers/programs/SingleProgram'));
const EditSchool = lazy(() => import('./containers/school/EditSchool'));
const EditProgram = lazy(() => import('./containers/programs/EditProgram'));
const EducationLevels = lazy(() =>
  import('./containers/settings/EducationLevels')
);
const AddEducationLevel = lazy(() =>
  import('./containers/settings/AddEducationLevel')
);
const EditEducationLevel = lazy(() =>
  import('./containers/settings/EditEducationLevel')
);
const ViewEducationLevel = lazy(() =>
  import('./containers/settings/ViewEducationLevel')
);
const Settings = lazy(() => import('./containers/settings/Settings'));
const Agents = lazy(() => import('./containers/agents/Agents'));
const AddAgent = lazy(() => import('./containers/agents/AddAgent'));
const EditAgent = lazy(() => import('./containers/agents/EditAgent'));
const ViewAgent = lazy(() => import('./containers/agents/ViewAgent'));
const SingleStudentEducationHistory = lazy(() =>
  import('./containers/students/SingleStudentEducationHistory')
);
const SingleStudentTestScores = lazy(() =>
  import('./containers/students/SingleStudentTestScores')
);
const SingleStudentVisaInformation = lazy(() =>
  import('./containers/students/SingleStudentVisaInformation')
);
const AllRolesAndPermissions = lazy(() =>
  import('./containers/RolesAndPermission/AllRolesAndPermissions')
);
const AddRolesAndPermissions = lazy(() =>
  import('./containers/RolesAndPermission/AddRolesAndPermissions')
);
const EditRolesAndPermissions = lazy(() =>
  import('./containers/RolesAndPermission/EditRolesAndPermissions')
);
const SingleRolesAndPermissionsComponent = lazy(() =>
  import('./components/RolesAndPermissions/SingleRolesAndPermissionsComponent')
);
const SingleRolesAndPermissions = lazy(() =>
  import('./containers/RolesAndPermission/SingleRolesAndPermissions')
);
const CrmUsers = lazy(() => import('./containers/crmUser/CrmUsers'));
const AddCrmUser = lazy(() => import('./containers/crmUser/AddCrmUser'));
const EditCrmUser = lazy(() => import('./containers/crmUser/EditCrmUser'));
const ViewCrmUser = lazy(() => import('./containers/crmUser/ViewCrmUser'));

const AcademicPartners = lazy(() =>
  import('./containers/academicPartner/AcademicPartners')
);
const AddAcademicPartner = lazy(() =>
  import('./containers/academicPartner/AddAcademicPartner')
);
const EditAcademicPartner = lazy(() =>
  import('./containers/academicPartner/EditAcademicPartner')
);
const ViewAcademicPartner = lazy(() =>
  import('./containers/academicPartner/ViewAcademicPartner')
);

const AddRequirements = lazy(() =>
  import('./containers/requirement/AddRequirements')
);
const EditRequirements = lazy(() =>
  import('./containers/requirement/EditRequirement')
);
const Countries = lazy(() => import('./containers/country/Countries'));
const AddCountry = lazy(() => import('./containers/country/AddCountry'));
const ViewCountry = lazy(() => import('./containers/country/ViewCountry'));
const EditCountry = lazy(() => import('./containers/country/EditCountry'));
const AgentProfile = lazy(() => import('./containers/agents/AgentProfile'));
const SearchProgramAndSchools = lazy(() =>
  import('./containers/search_program_school/SearchProgramAndSchools')
);
const MyApplications = lazy(() =>
  import('./containers/my_applications/MyApplications')
);
const SingleApplication = lazy(() =>
  import('./containers/my_applications/SingleApplication')
);
const AddApplicationRequirements = lazy(() =>
  import('./containers/programs/AddApplicationRequirements')
);
const Payments = lazy(() => import('./containers/payment/Payments'));
const Receipt = lazy(() => import('./containers/payment/Receipt'));
const ViewAgentProfile = lazy(() =>
  import('./containers/agents/ViewAgentProfile')
);
const DuplicateSchool = lazy(() =>
  import('./containers/school/DuplicateSchool')
);
const DuplicateProgram = lazy(() =>
  import('./containers/programs/DuplicateProgram')
);
const Payout = lazy(() => import('./containers/agents/Payout'));
const Gradings = lazy(() => import('./containers/gradings/Gradings'));
const AddGrading = lazy(() => import('./containers/gradings/AddGrading'));
const EditGrading = lazy(() => import('./containers/gradings/EditGrading'));
const ViewGrading = lazy(() => import('./containers/gradings/ViewGrading'));

const Recruiters = lazy(() =>
  import('./containers/website/containers/Recruiters')
);
const WebsiteStudents = lazy(() =>
  import('./containers/website/containers/WebsiteStudents')
);
const SchoolWebsite = lazy(() =>
  import('./containers/website/containers/SchoolWebsite')
);
const TermsAndConditions = lazy(() =>
  import('./containers/website/containers/TermsAndConditions')
);
const PrivacyPolicy = lazy(() =>
  import('./containers/website/containers/PrivacyPolicy')
);
const OurStory = lazy(() => import('./containers/website/containers/OurStory'));
const ContactUs = lazy(() =>
  import('./containers/website/containers/ContactUs')
);
const OurSolutions = lazy(() =>
  import('./containers/website/containers/OurSolutions')
);
const SingleBlog = lazy(() =>
  import('./containers/website/containers/EmigrationConsultantBlog')
);
const AustralianSpouseBlog = lazy(() =>
  import('./containers/website/containers/AustralianSpouseBlog')
);
const ReasonBlog = lazy(() =>
  import('./containers/website/containers/ReasonBlog')
);
// import Careers from './containers/website/containers/Careers';
const Counsellors = lazy(() => import('./containers/counsellors/Counsellors'));
const AddCounsellor = lazy(() =>
  import('./containers/counsellors/AddCounsellor')
);
const EditCounsellor = lazy(() =>
  import('./containers/counsellors/EditCounsellor')
);
const ViewCounsellor = lazy(() =>
  import('./containers/counsellors/ViewCounsellor')
);
const Blog = lazy(() => import('./containers/website/containers/Blog'));
const EditApplicationRequirements = lazy(() =>
  import('./containers/programs/EditApplicationRequirements')
);

const Templates = lazy(() => import('./containers/templates/Templates'));
const AddTemplate = lazy(() => import('./containers/templates/AddTemplate'));
const ViewTemplate = lazy(() => import('./containers/templates/ViewTemplate'));
const EditTemplate = lazy(() => import('./containers/templates/EditTemplate'));
const AddSingleApplicationRequirements = lazy(() =>
  import('./containers/my_applications/AddSingleApplicationRequirement')
);
const CardForm = lazy(() => import('./containers/payment/PayNow'));
const { PaymentSuccess } = lazy(() =>
  import('./containers/my_applications/PaymentSuccess')
);
const Notifications = lazy(() =>
  import('./components/auth_common/Notifications')
);
const Slabs = lazy(() => import('./containers/slabs/Slabs'));
const AddSlab = lazy(() => import('./containers/slabs/AddSlab'));
const EditSlab = lazy(() => import('./containers/slabs/EditSlab'));
const ViewSlab = lazy(() => import('./containers/slabs/ViewSlab'));
const { AddBulkPrograms } = lazy(() =>
  import('./containers/programs/AddBulkPrograms')
);
const Disciplines = lazy(() => import('./containers/discipline/Disciplines'));
const AddDiscipline = lazy(() =>
  import('./containers/discipline/AddDiscipline')
);
const ViewDiscipline = lazy(() =>
  import('./containers/discipline/ViewDiscipline')
);
const EditDiscipline = lazy(() =>
  import('./containers/discipline/EditDiscipline')
);

const WebsiteSingleSchool = lazy(() =>
  import('./containers/website/containers/WebsiteSingleSchool')
);
const WebsiteSingleProgram = lazy(() =>
  import('./containers/website/containers/WebsiteSingleProgram')
);

const Careers = lazy(() => import('./containers/website/containers/Careers'));
const Home = lazy(() => import('./containers/home/Home'));

function App() {
  useEffect(() => {
    const token = localStorage.getItem('token');

    setAuthToken(token);
    store.dispatch(currentUser());
  }, []);

  return (
    <Provider store={store}>
      <Suspense fallback={<div> Loading... </div>}>
        <Routes>
          <Route path={links.home} element={<Home />} />
          <Route path={links.register} element={<Register />} />
          <Route
            path={links.registerAsAgent}
            element={
              <BeforeLoginRoute>
                <RegisterAsAgent />
              </BeforeLoginRoute>
            }
          />
          <Route
            path={links.registerAsStudent}
            element={
              <BeforeLoginRoute>
                <RegisterAsStudent />
              </BeforeLoginRoute>
            }
          />
          <Route
            path={links.login}
            element={
              <BeforeLoginRoute>
                <Login />
              </BeforeLoginRoute>
            }
          />
          <Route
            path={links.forgotPassword}
            element={
              <BeforeLoginRoute>
                <ForgotPassword />
              </BeforeLoginRoute>
            }
          />
          <Route
            path={links.recoveryEmailed}
            element={
              <BeforeLoginRoute>
                <RecoveryEmailed />
              </BeforeLoginRoute>
            }
          />
          <Route
            path={links.resetPassword}
            element={
              <BeforeLoginRoute>
                <ResetPassword />
              </BeforeLoginRoute>
            }
          />
          <Route
            path={links.verifyEmail}
            element={
              <BeforeLoginRoute>
                <VerifyEmail />
              </BeforeLoginRoute>
            }
          />
          <Route
            path={links.dashBoardHome}
            element={
              <ProtectedRoute>
                <DashboardHome />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.notifications}
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />
          {/* profile */}
          <Route
            path={links.generalInformation}
            element={
              <ProtectedRoute>
                <GeneralInformation />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.educationHistory}
            element={
              <ProtectedRoute>
                <EducationHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.testScore}
            element={
              <ProtectedRoute>
                <TestScore />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.visaAndStudyPermit}
            element={
              <ProtectedRoute>
                <VisaAndStudyPermit />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.students}
            element={
              <ProtectedRoute>
                <Students />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.singleStudent}
            element={
              <ProtectedRoute>
                <SingleStudent />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.singleStudentEducationHistory}
            element={
              <ProtectedRoute>
                <SingleStudentEducationHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.singleStudentTestScore}
            element={
              <ProtectedRoute>
                <SingleStudentTestScores />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.singleStudentVisaInfo}
            element={
              <ProtectedRoute>
                <SingleStudentVisaInformation />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.SingleSchool}
            element={
              <ProtectedRoute>
                <SingleSchool />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.WebsiteSingleSchool}
            element={<WebsiteSingleSchool />}
          />
          <Route
            path={links.SingleProgram}
            element={
              <ProtectedRoute>
                <SingleProgram />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.WebsiteSingleProgram}
            element={<WebsiteSingleProgram />}
          />
          <Route
            path={links.SingleProgramID}
            element={
              <ProtectedRoute>
                <SingleProgram />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addStudent}
            element={
              <ProtectedRoute>
                <AddStudent />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.schools}
            element={
              <ProtectedRoute>
                <Schools />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addSchool}
            element={
              <ProtectedRoute>
                <AddSchool />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.duplicateSchool}
            element={
              <ProtectedRoute>
                <DuplicateSchool />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.educationLevel}
            element={
              <ProtectedRoute>
                <EducationLevels />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addEducationLevel}
            element={
              <ProtectedRoute>
                <AddEducationLevel />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editEducationLevel}
            element={
              <ProtectedRoute>
                <EditEducationLevel />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.ViewEducationLevel}
            element={
              <ProtectedRoute>
                <ViewEducationLevel />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.slabs}
            element={
              <ProtectedRoute>
                <Slabs />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addSlab}
            element={
              <ProtectedRoute>
                <AddSlab />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editSlab}
            element={
              <ProtectedRoute>
                <EditSlab />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.ViewSlab}
            element={
              <ProtectedRoute>
                <ViewSlab />
              </ProtectedRoute>
            }
          />

          <Route
            path={links.gradingScheme}
            element={
              <ProtectedRoute>
                <Gradings />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addGradingScheme}
            element={
              <ProtectedRoute>
                <AddGrading />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editGradingScheme}
            element={
              <ProtectedRoute>
                <EditGrading />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.ViewGradingScheme}
            element={
              <ProtectedRoute>
                <ViewGrading />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.settings}
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.agents}
            element={
              <ProtectedRoute>
                <Agents />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.agentProfile}
            element={
              <ProtectedRoute>
                <AgentProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewAgentProfile}
            element={
              <ProtectedRoute>
                <ViewAgentProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addAgent}
            element={
              <ProtectedRoute>
                <AddAgent />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editAgent}
            element={
              <ProtectedRoute>
                <EditAgent />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewAgent}
            element={
              <ProtectedRoute>
                <ViewAgent />
              </ProtectedRoute>
            }
          />

          <Route
            path={links.rolesAndPermissions}
            element={
              <ProtectedRoute>
                <AllRolesAndPermissions />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addRolesAndPermissions}
            element={
              <ProtectedRoute>
                <AddRolesAndPermissions />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editRolesAndPermissions}
            element={
              <ProtectedRoute>
                <EditRolesAndPermissions />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewRolesAndPermissions}
            element={
              <ProtectedRoute>
                <SingleRolesAndPermissions />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.crmUsers}
            element={
              <ProtectedRoute>
                <CrmUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addCrmUser}
            element={
              <ProtectedRoute>
                <AddCrmUser />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editCrmUser}
            element={
              <ProtectedRoute>
                <EditCrmUser />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewCrmUser}
            element={
              <ProtectedRoute>
                <ViewCrmUser />
              </ProtectedRoute>
            }
          />

          <Route
            path={links.academicPartners}
            element={
              <ProtectedRoute>
                <AcademicPartners />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addAcademicPartner}
            element={
              <ProtectedRoute>
                <AddAcademicPartner />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editAcademicPartner}
            element={
              <ProtectedRoute>
                <EditAcademicPartner />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewAcademicPartner}
            element={
              <ProtectedRoute>
                <ViewAcademicPartner />
              </ProtectedRoute>
            }
          />

          <Route
            path={links.Counsellors}
            element={
              <ProtectedRoute>
                <Counsellors />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addCounsellor}
            element={
              <ProtectedRoute>
                <AddCounsellor />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editCounsellor}
            element={
              <ProtectedRoute>
                <EditCounsellor />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewCounsellor}
            element={
              <ProtectedRoute>
                <ViewCounsellor />
              </ProtectedRoute>
            }
          />

          <Route
            path={links.addRequirement}
            element={
              <ProtectedRoute>
                <AddRequirements />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editRequirement}
            element={
              <ProtectedRoute>
                <EditRequirements />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.programs}
            element={
              <ProtectedRoute>
                <Programs />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.BulkUploadPrograms}
            element={
              <ProtectedRoute>
                <AddBulkPrograms />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addProgram}
            element={
              <ProtectedRoute>
                <AddProgram />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.duplicateProgram}
            element={
              <ProtectedRoute>
                <DuplicateProgram />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editProgram}
            element={
              <ProtectedRoute>
                <EditProgram />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editSchool}
            element={
              <ProtectedRoute>
                <EditSchool />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.country}
            element={
              <ProtectedRoute>
                <Countries />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addCountry}
            element={
              <ProtectedRoute>
                <AddCountry />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewCountry}
            element={
              <ProtectedRoute>
                <ViewCountry />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editCountry}
            element={
              <ProtectedRoute>
                <EditCountry />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.discipline}
            element={
              <ProtectedRoute>
                <Disciplines />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addDiscipline}
            element={
              <ProtectedRoute>
                <AddDiscipline />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewDiscipline}
            element={
              <ProtectedRoute>
                <ViewDiscipline />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editDiscipline}
            element={
              <ProtectedRoute>
                <EditDiscipline />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.templates}
            element={
              <ProtectedRoute>
                <Templates />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addTemplate}
            element={
              <ProtectedRoute>
                <AddTemplate />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewTemplate}
            element={
              <ProtectedRoute>
                <ViewTemplate />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editTemplate}
            element={
              <ProtectedRoute>
                <EditTemplate />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.searchProgramAndSchools}
            element={
              <ProtectedRoute>
                <SearchProgramAndSchools />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.searchProgramAndSchoolsByStudent}
            element={
              <ProtectedRoute>
                <SearchProgramAndSchools />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.myApplications}
            element={
              <ProtectedRoute>
                <MyApplications />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewApplication}
            element={
              <ProtectedRoute>
                <SingleApplication />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.paymentSuccessful}
            element={
              <ProtectedRoute>
                <PaymentSuccess />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addApplicationRequirements}
            element={
              <ProtectedRoute>
                <AddSingleApplicationRequirements />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.payments}
            element={
              <ProtectedRoute>
                <Payments />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.receipt}
            element={
              <ProtectedRoute>
                <Receipt />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.agentPayout}
            element={
              <ProtectedRoute>
                <Payout />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addApplicationRequirement}
            element={
              <ProtectedRoute>
                <AddApplicationRequirements />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editApplicationRequirement}
            element={
              <ProtectedRoute>
                <EditApplicationRequirements />
              </ProtectedRoute>
            }
          />
          {/* webstie Routes */}
          <Route path={links.recruiters} element={<Recruiters />} />
          <Route path={links.schoolWebsite} element={<SchoolWebsite />} />
          <Route path={links.websiteStudent} element={<WebsiteStudents />} />
          <Route
            path={links.termsAndConditions}
            element={<TermsAndConditions />}
          />
          <Route path={links.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={links.about} element={<OurStory />} />
          <Route path={links.contactUs} element={<ContactUs />} />
          <Route path={links.ourSolutions} element={<OurSolutions />} />
          <Route path={links.singleBlog} element={<SingleBlog />} />
          <Route
            path={links.australianSpouse}
            element={<AustralianSpouseBlog />}
          />
          <Route path={links.reason} element={<ReasonBlog />} />

          <Route path={links.careers} element={<Careers />} />
          <Route path={links.blogs} element={<Blog />} />
          <Route path={'/payments/test'} element={<CardForm />} />
          {/* <Route component={PageNotFound} /> */}
        </Routes>
      </Suspense>
    </Provider>
  );
}

export default App;
